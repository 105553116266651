// 全ての Bootstrap の名前付きエクスポート（named export）をインポート
import * as bootstrap from 'bootstrap';
 

// Bootstrap Icons の CSS をインポート
import 'bootstrap-icons/font/bootstrap-icons.css';

// 初期処理をインポート
import * as initialize from './initialize';

// swiper
import * as swiper_custom from './swiper-custom';

// スタイルシート（Sass）をインポート
import './custom.scss';

// 画面遷移
$('.nav-link').on('click', (event) => {
  const value = $(event.target).attr('href');
  console.log(value);
  if (value !== '#accessibility-statement') {
    $('#page-main').removeClass('dismiss');
    $('#accessibility-statement').addClass('dismiss');
  }else if (value === '#accessibility-statement') {
    $('#page-main').addClass('dismiss');
    $('#accessibility-statement').removeClass('dismiss');
  }
});
